interface ITarif {
  id: number;
  name: string;
  price: string;
  description: string;
  type: "monthly" | "yearly";
  features: string[];
}

export const tarifs: ITarif[] = [
  {
    id: 1,
    name: "Корпоративный",
    price: "100 000",
    description:
      "Создайте превосходный сервис с продвинутыми функциями Prochat",
    type: "monthly",
    features: [
      "Полное сопровождение и приоритетная поддержка",
      "Чат для мобильного приложения (Mobile SDK)",
      "Маршрутизация чатов",
      "Видеозвонки",
      "Чат API",
    ],
  },
  {
    id: 2,
    name: "Профессиональный",
    price: "200 000",
    description: "Сократите нагрузку на службу поддержки, автоматизируйте коммуникации, управляйте звонками от клиентов",
    type: "monthly",
    features: [
      "Модуль «Телефония Плюс»",
      "Интеграция с WhatsApp",
      "Голосовые сообщения в Telegram, VK, WhatsApp",
      "Интерактивные сообщения",
      "Спасение чатов"
    ],
  },
  {
    id: 3,
    name: "Базовая",
    price: "400 000",
    description: "Приглашайте посетителей в диалог, конвертируйте обращения в продажи",
    type: "monthly",
    features: [
      "Активные приглашения и кнопки быстрого начала чата",
      "Интеграция с Авито",
      "Шаблоны ответов для оператора",
      "Интерактивные сообщения",
      "Защита от спама"
    ],
  },
  {
    id: 4,
    name: "Начальная",
    price: "0",
    description: "Отвечайте клиентам в чате на сайте, соцсетях и мессенджерах из одного приложения",
    type: "monthly",
    features: [
      "Каналы: Telegram, VK, Viber, Email, Apple Business Chat",
      "Обратный звонок",
      "Оффлайн-форма",
      "Работает на всех устройствах",
    ],
  },
];
