import contact from "../../assets/images/contacts.svg";
import password from "../../assets/images/password.svg";
import control from "../../assets/images/control.svg";
import accounts from "../../assets/images/accounts.svg";

export interface IFeature {
  title: string;
  description: string;
  img: string;
}

export const features: IFeature[] = [
  {
    title: "Контакты в одном месте",
    description:
      "Храните персональные данные в ProChat, а не в телефонах менеджеров",
    img: contact,
  },
  {
    title: "Не передавайте пароли",
    description:
      "С ProChat операторы отвечают клиентам, не получая доступ к соцсетям и мессенджерам",
    img: password,
  },
  {
    title: "Контролируйте переписку",
    description: "Следите за качеством общения, анализируя сохраненные диалоги",
    img: control,
  },
  {
    title: "Аккаунты - ваша собственность",
    description:
      "Предотвратить утечку клиентской базы легче, чем компенсировать урон от ее потери",
    img: accounts,
  },
];
