import React, { useCallback, useEffect, useState } from "react";
import "./CContact.css";
import { toast } from "react-toastify";
import CButton from "../CButton";
import { useForm } from "react-hook-form";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { IContactProps, Inputs } from "./CContact.types";
import { MailService } from "../../services/MailService";

const CContact: React.FC<IContactProps> = ({ email }) => {
  const [reCaptchaToken, setReCaptchaToken] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const mailService = new MailService();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
  } = useForm<Inputs>();

  useEffect(() => {
    if (email) {
      setValue("email", email);
    }
  }, [email, setValue]);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    const token = await executeRecaptcha("mail");
    setReCaptchaToken(token);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  const sendContact = async (contact: Inputs) => {
    if (!reCaptchaToken) {
      toast.error("Ошибка reCAPTCHA");
      return;
    }

    setIsLoading(true);

    const formData = new FormData();
    formData.append("email", contact.email);
    formData.append("message", ""); 
    formData.append("name", contact.userName);
    formData.append("phone", contact.phoneNumber);
    formData.append("recaptchaResponse", reCaptchaToken);

    try {
      await mailService.sendMail(formData);
      setIsLoading(false);
      reset();
      toast.success("Заявка отправлена успешно!");
    } catch (error) {
      setIsLoading(false);
      toast.error("Ошибка при отправке заявки!");
      console.error("Error sending contact:", error);
    }
  };

  return (
    <form className="ccontact" onSubmit={handleSubmit(sendContact)}>
      <h2 className="ccontact__title">Оставить заявку</h2>
      <div>
        <input
          className="ccontact__input"
          placeholder="Введите ваше имя"
          {...register("userName", { required: true })}
        />
        {errors.userName && (
          <p className="danger__text ccontact__danger-text">
            Поле имени обязательно для заполнения
          </p>
        )}
      </div>
      <div>
        <input
          className="ccontact__input"
          type="tel"
          placeholder="Введите ваш телефон"
          {...register("phoneNumber", { required: true })}
        />
        {errors.phoneNumber && (
          <p className="danger__text ccontact__danger-text">
            Поле телефона обязательно для заполнения
          </p>
        )}
      </div>
      <div>
        <input
          className="ccontact__input"
          type="email"
          placeholder="Введите вашу электронную почту"
          {...register("email", { required: true })}
        />
        {errors.email && (
          <p className="danger__text ccontact__danger-text">
            Поле электронной почты обязательно для заполнения
          </p>
        )}
      </div>

      <CButton title="Подключить" disabled={isLoading} />
    </form>
  );
};

export default CContact;
