import React from "react";
import { Link, Outlet } from "react-router-dom";
import "./AuthLayout.css";

const AuthLayout: React.FC = () => {
  return (
    <>
      <header className="auth__header wrapper">
        <Link to={"/"} className="auth__header-title">
          Pro<span>Chat</span>
        </Link>
      </header>

      <Outlet />
    </>
  );
};

export default AuthLayout;
