import React from "react";
import { CPartnersProps } from "./CPartners.types";
import "./CPartners.css";

const CPartners: React.FC<CPartnersProps> = ({ partners }) => {
  return (
    <div className="cpartners">
      {partners.map((partner) => {
        return (
          <a
            href={partner.website}
            target="_blank"
            rel="noreferrer"
            key={partner.id}
          >
            <img
              className="cpartners__img"
              src={partner.logo}
              alt={partner.name}
            />
          </a>
        );
      })}
    </div>
  );
};

export default CPartners;
