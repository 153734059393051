import React from "react";
import "./Tarif.css";
import tarifIntro from "../../assets/images/tarifIntro.svg";
import CTab from "../../components/CTab/CTab";
import { tarifs } from "../../db/tarifs";
import CButton from "../../components/CButton";
import tick from "../../assets/icons/tick.svg";
import { faqs } from "../../db/faqs";
import CApplication from "../../components/CApplication";
import { services } from "../../db/services";
import available from "../../assets/icons/available.svg";
import devider from "../../assets/icons/devider.svg";

const Tarif: React.FC = () => {
  const tabs = [
    {
      label: "месяц",
    },
    {
      label: "годовой",
    },
  ];

  return (
    <>
      <div className="tarif__intro wrapper">
        <img src={tarifIntro} alt="tarif-intro" className="tarif__intro-img" />
        <div className="tarif__intro-content">
          <h2 className="tarif__intro-title">
            Превратите посетителей в покупателей
          </h2>
          <p className="tarif__intro-description">
            Выберите версию Prochat, которая лучше всего подходит для решения
            ваших задач
          </p>
        </div>
      </div>

      <div className="tarif__plans wrapper">
        <div className="tarif__plans-header">
          <h2 className="tarif__plans-title">Тарифные планы</h2>
          <div className="tarif__plans-tabs">
            <CTab tabs={tabs} />
          </div>
        </div>

        <div className="tarif__plan-list">
          {tarifs.map((tarif) => {
            return (
              <div className="tarif__plan" key={tarif.id}>
                <div className="tarif__plan-price">
                  <h2 className="tarif__plan-price-amount">{tarif.price}</h2>
                  <span className="tarif__plan-price-period">
                    СУМ <br />
                    /в месяц
                  </span>
                </div>
                <p className="tarif__plan-name">{tarif.name}</p>
                <p className="tarif__plan-description">{tarif.description}</p>
                <div className="tarif__plan-features">
                  {tarif.features.map((feature) => {
                    return (
                      <p className="tarif__plan-feature" key={feature}>
                        <img src={tick} alt="tick" />
                        {feature}
                      </p>
                    );
                  })}
                </div>

                <CButton title="Подключить" className="tarif__plan-button" />
              </div>
            );
          })}
        </div>
      </div>

      <div className="tarif__functions wrapper">
        <table className="tarif__table">
          <tr className="tarif__row">
            <td className="tarif__cell"></td>
            <td className="tarif__cell">
              <p>Корпоративный</p>
              <button className="tarif__button">Подключить</button>
            </td>
            <td className="tarif__cell">
              <p>Профессиональный</p>
              <button className="tarif__button">Подключить</button>
            </td>
            <td className="tarif__cell">
              <p>Базовый</p>
              <button className="tarif__button">Подключить</button>
            </td>
            <td className="tarif__cell">
              <p>Начальный</p>
              <button className="tarif__button">Подключить</button>
            </td>
          </tr>
          <tr className="tarif__row">
            <th className="tarif__header">Каналы</th>
            <th className="tarif__header"></th>
            <th className="tarif__header"></th>
            <th className="tarif__header"></th>
            <th className="tarif__header"></th>
          </tr>
          {services.map((service, index) => {
            return (
              <tr className="tarif__row" key={index}>
                <td className="tarif__service-title">{service.title}</td>
                {service.features.map((feature, featureIndex) => {
                  return (
                    <td className="tarif__feature" key={featureIndex}>
                      {feature.isAvailable ? (
                        <img
                          className="tarif__icon tarif__icon--available"
                          src={available}
                          alt="available"
                        />
                      ) : (
                        <img
                          className="tarif__icon tarif__icon--devider"
                          src={devider}
                          alt="devider"
                        />
                      )}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </table>
      </div>

      <div className="tarif__faqs wrapper">
        <h2 className="tarif__faqs-title">
          Часто задаваемые <br /> вопросы
        </h2>

        <div className="tarif__faqs-list">
          {faqs.map((faq, index) => {
            return (
              <div key={index} className="tarif__faqs-item">
                <h3 className="tarif__faqs-question">{faq.question}</h3>
                <p className="tarif__faqs-answer">{faq.answer}</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="wrapper">
        <CApplication />
      </div>
    </>
  );
};

export default Tarif;
