interface IFaq {
  question: string;
  answer: string;
}

export const faqs: IFaq[] = [
  {
    question: "Что будет после того, как закончится бесплатный 14-дневный период про-версии?",
    answer: "Вы можете купить профессиональную версию, например, на год и продолжать пользоваться всеми возможностями Prochat. Или перейти на базовую или начальную, в которых меньше функций",
  },
  {
    question: "Вы даете операторов или только чат?",
    answer: "Мы предоставляем только программное обеспечение: чат на сайт и приложения операторов.",
  },
  {
    question: "Могут ли сотрудники, которые не общаются с клиентами, работать в Prochat?",
    answer: "Стоимость подписки будет рассчитываться только по количеству операторов, которые общаются с клиентами. А остальная команда сможет воспользоваться возможностями Prochat бесплатно.",
  },
  {
    question: "Как оплатить профессиональную версию?",
    answer: "Есть несколько способов: вы можете оплатить от юридического лица с расчетного счета компании или от физического лица из личного кабинета банковской картой (Visa или MasterCard).",
  },
];
