import React from "react";
import { CCardProps } from "./CCard.types";
import "./CCard.css";

const CCard: React.FC<CCardProps> = ({ title, description, img }) => {
  return (
    <div className="ccard">
      <div className="ccard__image">
        <img src={img} alt={title} />
      </div>
      <h2 className="ccard__title">{title}</h2>
      <p className="ccard__description">{description}</p>
    </div>
  );
};

export default CCard;
