import React from "react";
import "./Login.css";
import CTitle from "../../../components/CTitle";
import CInput from "../../../components/CInput";
import { CiMail } from "react-icons/ci";
import { CiLock } from "react-icons/ci";
import CButton from "../../../components/CButton";
import { Link } from "react-router-dom";

const Login: React.FC = () => {
  return (
    <div className="login__wrapper">
      <div className="login">
        <CTitle />

        <div className="login__header">
          <h2 className="login__title">Вход в ProChat</h2>
          <p className="login__subtitle">Войдите в ваш аккаунт</p>
        </div>

        <CInput placeholder="Электронная почта" startIcon={<CiMail />} />

        <CInput placeholder="Пароль" startIcon={<CiLock />} type="password" />

        <p className="login__restore">Восстановить пароль</p>

        <Link to={"/register"} className="login__register-link">
          Нет аккаунта? <span>Зарегистрироваться</span>
        </Link>

        <CButton title="Войти" />
      </div>
    </div>
  );
};

export default Login;
