import React, { useState } from "react";
import "./Home.css";
import girl from "../../assets/images/girl.svg";
import laptop from "../../assets/images/laptop.svg";
import stats from "../../assets/images/stats.svg";
import dialog from "../../assets/images/dialog.svg";
import socialsImg from "../../assets/images/socials-img.svg";
import jivo from "../../assets/images/jivo.svg";
import vk from "../../assets/icons/vk.svg";
import telegram from "../../assets/icons/telegram.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";
import viber from "../../assets/icons/viber.svg";
import ok from "../../assets/icons/ok.svg";
import crm from "../../assets/images/crm.svg";
import brands from "../../assets/images/brands.svg";
import jivoMobile from "../../assets/images/jivo-mobile.svg";
import CCard from "../../components/CCard";
import { features } from "./features";
import "react-toastify/dist/ReactToastify.css";
import CModal from "../../components/CModal";
import CContact from "../../components/CContact";
import CApplication from "../../components/CApplication";
import CPartners from "../../components/CPartners";
import { partners } from "../../db/partners";

const Home: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="home__intro wrapper">
        <div className="home__intro-content">
          <h2 className="home__intro-title">
            Платформа для <br /> связи с клиентами
          </h2>
          <p className="home__intro-description">
            Общайтесь с посетителями вашего сайта. <br /> Постройте великолепный
            сервис поддержки <br /> в мессенджерах
          </p>
          <button
            className="home__intro-button home__btn"
            onClick={toggleModal}
          >
            Оставить заявку
          </button>
        </div>
        <div className="home__intro-imageContainer">
          <img className="home__intro-image" src={girl} alt="girl" />
        </div>
      </div>

      <div className="home__stats wrapper">
        <h2 className="home__stats-title">
          Наши цифры <br /> говорят сами за себя
        </h2>

        <div className="home__stats-items">
          <div className="home__stats-item" style={{ background: "#FA7758" }}>
            <h2 className="home__stats-number">200 тыс.</h2>
            <div className="home__stats-icon">
              <img className="home__stats-icon" src={laptop} alt="laptop" />
            </div>
            <p className="home__stats-description">Безнесов доверяют ProChat</p>
          </div>

          <div className="home__stats-item" style={{ background: "#336699" }}>
            <h2 className="home__stats-number">№1</h2>
            <div className="home__stats-icon">
              <img src={stats} alt="stats" />
            </div>
            <p className="home__stats-description">
              По количеству пользователей
            </p>
          </div>

          <div className="home__stats-item" style={{ background: "#009966" }}>
            <h2 className="home__stats-number">14 млн.</h2>
            <div className="home__stats-icon">
              <img className="home__stats-icon" src={dialog} alt="dialog" />
            </div>
            <p className="home__stats-description">
              Количество диалогов в месяц
            </p>
          </div>
        </div>
      </div>

      <div className="home__socials wrapper">
        <h2 className="home__socials-title">
          Ваши клиенты уже <br /> в мессенджерах
        </h2>
        <p className="home__socials-subtitle">
          Подружитесь с ними в удобной для них среде!
        </p>

        <div className="home__socials-content">
          <img
            className="home__socials-image"
            src={socialsImg}
            alt="socials-img"
          />
          <div className="home__socials-details">
            <div className="home__socials-icons">
              <a href="/">
                <img className="home__socials-icon" src={vk} alt="vk" />
              </a>
              <a href="/">
                <img
                  className="home__socials-icon"
                  src={telegram}
                  alt="telegram"
                />
              </a>
              <a href="/">
                <img
                  className="home__socials-icon"
                  src={whatsapp}
                  alt="whatsapp"
                />
              </a>
              <a href="/">
                <img className="home__socials-icon" src={viber} alt="viber" />
              </a>
              <a href="/">
                <img className="home__socials-icon" src={ok} alt="ok" />
              </a>
            </div>
            <p className="home__socials-description">
              Объедините все каналы связи в одном приложении: чат на сайте,
              соцсети, мессенджеры и звонки. Отвечайте клиентам там, где им
              удобно
            </p>
            <button
              className="home__socials-button home__btn"
              onClick={toggleModal}
            >
              Подключить
            </button>
          </div>
        </div>
      </div>

      <div className="home__jivo wrapper">
        <img src={jivo} className="home__jivo-img" alt="jivo" />
        <img
          src={jivoMobile}
          className="home__jivo-mobile-img"
          alt="jivo-mobile"
        />
      </div>

      <div className="home__integration wrapper">
        <h2 className="home__integration-title">
          Легкое внедрение <br /> и интеграция с любой CRM
        </h2>
        <p className="home__integration-subtitle">
          Быстрый старт и удобная работа с клиентами
        </p>

        <div className="home__integration-content">
          <img className="home__integration-image" src={crm} alt="crm" />
          <div className="home__integration-details">
            <img
              className="home__integration-brands-image"
              src={brands}
              alt="brands"
            />
            <button className="home__btn" onClick={toggleModal}>
              Подключить
            </button>
          </div>
        </div>
      </div>

      <div className="home__security wrapper">
        <h2 className="home__security-title">Защитите свой бизнес</h2>
        <p className="home__security-description">
          Возьмите под контроль общение с клиентами в мессенджерах. Уволившиеся
          сотрудники и потерянные телефоны больше не повод для тревоги
        </p>
        <div className="home__security-features">
          {features.map((feature, index) => {
            return (
              <CCard
                key={index}
                title={feature.title}
                description={feature.description}
                img={feature.img}
              />
            );
          })}
        </div>
      </div>

      <div className="wrapper">
        <CPartners partners={partners} />
      </div>

      <div className="wrapper">
        <CApplication />
      </div>

      <CModal
        show={isModalOpen}
        onClose={toggleModal}
        children={<CContact />}
      />
    </>
  );
};

export default Home;
