import React from "react";
import "./Register.css";
import CTitle from "../../../components/CTitle";
import CInput from "../../../components/CInput";
import { CiLock, CiMail, CiUser } from "react-icons/ci";
import { Link } from "react-router-dom";
import CButton from "../../../components/CButton";

const Register: React.FC = () => {
  return (
    <div className="register__wrapper">
      <div className="register">
        <CTitle />

        <div className="register__header">
          <h2 className="register__title">Регистрация в ProChat</h2>
          <p className="register__subtitle">Создайте аккаунт</p>
        </div>

        <CInput placeholder="Ваше имя" startIcon={<CiUser />} />

        <CInput placeholder="Электронная почта" startIcon={<CiMail />} />

        <CInput placeholder="Пароль" startIcon={<CiLock />} type="password" />

        <Link to={"/login"} className="register__login-link">
          У вас уже есть аккаунт? <span>Войти</span>
        </Link>

        <CButton title="Зарегистрироваться" />
      </div>
    </div>
  );
};

export default Register;
