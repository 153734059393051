import React from "react";
import "./404.css";

const NotFoundPage: React.FC = () => {
  return (
    <div className="not__found">
      <h2 className="not__found-title">404</h2>
      <p className="not__found-description">
        The page you were looking for doesn't exist
      </p>
    </div>
  );
};

export default NotFoundPage;
