import React, { useState } from "react";
import "./About.css";
import CPartners from "../../components/CPartners";
import { partners } from "../../db/partners";
import people from "../../assets/images/people.svg";
import managers from "../../assets/images/managers.svg";
import CModal from "../../components/CModal";
import CContact from "../../components/CContact";
import CApplication from "../../components/CApplication";

const About: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  return (
    <>
      <div className="about__intro wrapper">
        <div className="about__intro-content">
          <h2 className="about__intro-title">
            Это{" "}
            <span>
              <span className="about__intro-title-span">Pro</span>Chat
            </span>
          </h2>
          <p className="about__intro-description">
            Мы помогаем компаниям строить безупречный клиентский сервис и
            достигать с его помощью отличных бизнес-результатов!
          </p>

          <button className="about__intro-btn" onClick={toggleModal}>
            Оставить заявку
          </button>
        </div>

        <img className="about__intro-img" src={managers} alt="managers" />
      </div>

      <div className="about__partners wrapper">
        <h2 className="about__partners-title">Нам доверяют</h2>
        <CPartners partners={partners} />
      </div>

      <div className="about__mission wrapper">
        <div>
          <img className="about__mission-img" src={people} alt="people" />
        </div>

        <div>
          <h2 className="about__mission-title">
            Миссия и принципы
            <p>
              <span>Pro</span>Chat
            </p>
          </h2>

          <div className="mission__content">
            <p className="mission__content-title">Надежность</p>
            <p className="mission__content-description">
              Мы выстраиваем долгосрочные и надежные взаимоотношения с
              партнерами и клиентами на базе профессионализма, технологической
              устойчивости и финансовой независимости.
            </p>
          </div>

          <div className="mission__content">
            <p className="mission__content-title">Профессионализм</p>
            <p className="mission__content-description">
              Мы непрерывно отслеживаем тенденции рынка, изучаем опыт признанных
              экспертов, чтобы сделать лучше собственную экспертизу.
            </p>
          </div>

          <div className="mission__content">
            <p className="mission__content-title">Развитие</p>
            <p className="mission__content-description">
              Мы непрерывно работаем над улучшением продукта, следим за трендами
              на рынке клиентского сервиса и быстро реагируем на новые запросы и
              требования.
            </p>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <CApplication />
      </div>

      <CModal
        show={isModalOpen}
        onClose={toggleModal}
        children={<CContact />}
      />
    </>
  );
};

export default About;
