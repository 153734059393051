import uzcard from "../assets/images/uzcard.png";
import humo from "../assets/images/humo.png";
import imzo from "../assets/images/imzo.png";
import payway from "../assets/images/pw.svg";
import ucell from "../assets/images/ucell.png";

export interface IPartner {
  id: number;
  name: string;
  logo: string;
  website: string;
}

export const partners: IPartner[] = [
  {
    id: 1,
    name: "Humo",
    logo: humo,
    website: "https://humocard.uz",
  },
  {
    id: 2,
    name: "Uzcard",
    logo: uzcard,
    website: "https://uzcard.uz",
  },
  {
    id: 3,
    name: "Imzo",
    logo: imzo,
    website: "https://imzo.uz",
  },
  {
    id: 4,
    name: "PayWay",
    logo: payway,
    website: "https://1pay.uz",
  },
  {
    id: 5,
    name: "Ucell",
    logo: ucell,
    website: "https://ucell.uz",
  },
];
