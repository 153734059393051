import React, { useEffect, useRef } from "react";
import { CModalProps } from "./CModal.types";
import "./CModal.css";

const CModal: React.FC<CModalProps> = ({ show, onClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        onClose();
      }
    };

    if (show) {
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleKeyDown);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [show, onClose]);

  if (!show) {
    return null;
  }

  return (
    <div className="modal__overlay">
      <div className="modal" ref={modalRef}>
        <button className="modal__close-btn" onClick={onClose}>
          &times;
        </button>
        <div className="modal__content">{children}</div>
      </div>
    </div>
  );
};

export default CModal;
