import React, { useState } from "react";
import operator from "../../assets/images/operator.svg";
import { IContact } from "../../pages/Home/IContact";
import "./CApplication.css";
import CModal from "../CModal";
import CContact from "../CContact";

const CApplication: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [contact, setContact] = useState<IContact>({
    name: "",
    phone: "",
    message: "",
    email: "",
    recaptchaResponse: "",
  });

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setContact({ ...contact, [e.target.name]: e.target.value });
  };

  return (
    <div className="capplication">
      <img className="capplication__img" src={operator} alt="operator" />
      <div className="capplication__content">
        <h2 className="capplication__title">Всего три минуты на установку</h2>
        <div className="capplication__form">
          <input
            className="capplication__input"
            type="email"
            placeholder="Электронная почта"
            onChange={handleChange}
            name="email"
          />
          <button
            className="capplication__button"
            type="submit"
            onClick={toggleModal}
          >
            Подключить
          </button>
        </div>
      </div>

      <CModal
        show={isModalOpen}
        onClose={toggleModal}
        children={<CContact email={contact.email}/>}
      />
    </div>
  );
};

export default CApplication;
