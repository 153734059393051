import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Home from "../pages/Home";
import NotFoundPage from "../pages/NotFound/404";
import About from "../pages/About";
import Tarif from "../pages/Tarifs/Tarif";
import Partner from "../pages/Partner";
import Layout from "./Layout";
import AuthLayout from "../pages/Auth/AuthLayout";
import Register from "../pages/Auth/Register";
import Login from "../pages/Auth/Login";
import AccountLayout from "../pages/AccountLayout";
import Account from "../pages/Account";
import Chat from "../pages/Chat";
import Settings from "../pages/Settings";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/tarif",
        element: <Tarif />,
      },
      {
        path: "/partner",
        element: <Partner />,
      },
    ],
  },
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/register",
        element: <Register />,
      },
      {
        path: "/login",
        element: <Login />,
      },
    ],
  },
  {
    path: "/account",
    element: <AccountLayout />,
    children: [
      {
        index: true,
        element: <Account />,
      },
      {
        path: "/account/chat",
        element: <Chat />,
      },
      {
        path: "/account/settings",
        element: <Settings />,
      },
    ],
  },
  {
    path: "*",
    element: <NotFoundPage />,
  },
]);

const AppRouter: React.FC = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer />
    </>
  );
};

export default AppRouter;
