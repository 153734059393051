import React from "react";
import "./CTitle.css";

const CTitle: React.FC = () => {
  return (
    <h2 className="ctitle">
      Pro<span>Chat</span>
    </h2>
  );
};

export default CTitle;
