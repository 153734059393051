import React, { useState } from "react";
import { CInputProps } from "./CInput.types";
import "./CInput.css";
import { FaRegEyeSlash } from "react-icons/fa";
import { IoEyeOutline } from "react-icons/io5";

const CInput: React.FC<CInputProps> = ({
  value,
  placeholder,
  disabled,
  type,
  onChange,
  startIcon,
  endIcon,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="cinput__container">
      {startIcon && (
        <span className="cinput__icon cinput__icon-start">{startIcon}</span>
      )}

      <input
        className="cinput"
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        type={type === "password" ? (showPassword ? "text" : "password") : type}
        onChange={onChange}
      />

      {type === "password" && (
        <span
          className="cinput__icon cinput__icon-end cinput__icon-eye"
          onClick={togglePassword}
        >
          {showPassword ? <FaRegEyeSlash /> : <IoEyeOutline />}
        </span>
      )}

      {endIcon && (
        <span className="cinput__icon cinput__icon-end">{endIcon}</span>
      )}
    </div>
  );
};

export default CInput;
