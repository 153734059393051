interface IFeature {
  title: string;
  isAvailable: boolean;
}

interface IService {
  title: string;
  features: IFeature[];
}

export const services: IService[] = [
  {
    title: "Чат для сайта",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: true,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: true,
      },
    ],
  },
  {
    title: "Cоциальные сети",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: false,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: false,
      },
    ],
  },
  {
    title: "Видеозвонки",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: true,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: true,
      },
    ],
  },
  {
    title: "Mobile SDK",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: true,
      },
      {
        title: "Базовый",
        isAvailable: false,
      },
      {
        title: "Начальный",
        isAvailable: true,
      },
    ],
  },
  {
    title: "Интеграция с Авито",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: true,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: true,
      },
    ],
  },
  {
    title: "Модуль Телефония плюс",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: false,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: false,
      },
    ],
  },
  {
    title: "Обмен голосовыми сообщениями в WhatsApp, Telegram, VK",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: false,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: false,
      },
    ],
  },
  {
    title: "Прием заказов из веб-форм и онлайн-магазинов",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: true,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: false,
      },
    ],
  },
  {
    title: "Обратный звонок",
    features: [
      {
        title: "Корпоративный",
        isAvailable: true,
      },
      {
        title: "Профессиональный",
        isAvailable: true,
      },
      {
        title: "Базовый",
        isAvailable: true,
      },
      {
        title: "Начальный",
        isAvailable: true,
      },
    ],
  },
];
