import axios from "axios";
import { BASE_API } from "../constants/constants";

export class MailService {
  async sendMail(formData: FormData) {
    try {
      const response = await axios.post(`${BASE_API}/mailsender/`, formData);

      return response;
    } catch (error) {
      throw error;
    }
  }
}
