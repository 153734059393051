import React from "react";
import "./styles/global.css";
import { useTranslation } from "react-i18next";
import Router from "./routes/Router";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { RECAPTCHA_KEY } from "./constants/constants";

const App: React.FC = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  (window as any).chatSettings = {
    changeLanguage,
  };

  return (
    <GoogleReCaptchaProvider reCaptchaKey={RECAPTCHA_KEY}>
      <Router />
    </GoogleReCaptchaProvider>
  );
};

export default App;
