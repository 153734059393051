import React from "react";
import "./AccountLayout.css";
import { Outlet } from "react-router-dom";
import Sidebar from "../../layouts/Sidebar";

const AccountLayout: React.FC = () => {
  return (
    <div className="account__layout">
      <Sidebar />
      <Outlet />
    </div>
  );
};

export default AccountLayout;
