import React, { useState } from "react";
import { CTabProps } from "./CTab.types";
import "./CTab.css";

const CTab: React.FC<CTabProps> = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index: number) => {
    setActiveTab(index);
  };

  return (
    <div className="ctab">
      <div className="ctab__tabs">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={`ctab__tab ${
              index === activeTab ? "ctab__tab-active" : ""
            }`}
            onClick={() => handleTabClick(index)}
          >
            {tab.label}
          </button>
        ))}
      </div>
      <div className="ctab__content">{tabs[activeTab].content}</div>
    </div>
  );
};

export default CTab;
