import React from "react";
import "./CLevel.css";
import { CLevelProps } from "./CLevelTypes";

const CLevel: React.FC<CLevelProps> = ({
  title,
  description,
  img,
  percent,
  payment,
}) => {
  return (
    <div className="clevel">
      <p className="clevel__title">{title}</p>
      <p className="clevel__description">{description}</p>
      <div className="clevel__img">
        <span className="devider"></span>
        <img src={img} alt={img} />
      </div>
      <p className="clevel__percent">{percent}</p>
      <p className="clevel__payment">{payment}</p>
    </div>
  );
};

export default CLevel;
