import React from "react";
import "./Partner.css";
import CApplication from "../../components/CApplication";
import CLevel from "./CLevel/CLevel";
import beginner from "../../assets/images/beginner.svg";
import master from "../../assets/images/master.svg";
import professional from "../../assets/images/professional.svg";
import { IoCallSharp } from "react-icons/io5";
import { IoMdMail } from "react-icons/io";
import partnerIntro from "../../assets/images/partner-intro.svg";
import partnerOperator from "../../assets/images/partner-operator.svg";

const Partner: React.FC = () => {
  return (
    <>
      <div className="partner__intro wrapper">
        <div className="partner__intro-img">
          <img src={partnerIntro} alt="partner-intro" />
        </div>
        <div className="partner__intro-content">
          <h2 className="partner__intro-title">Наши партнеры <br /> уже заработали</h2>
          <p className="partner__intro-amount">243 000 000</p>
          <button className="partner__intro-btn">Оставить заявку</button>
        </div>

        <img className="partner__operator" src={partnerOperator} alt="partner-operator" />
      </div>

      <div className="wrapper">
        <h2 className="level__title">
          Зарабатывайте,
          <br /> подключая ProChat вашим клиентам
        </h2>

        <p className="level__description">
          Вы получаете вознаграждение каждый раз, когда клиент, приведенный
          вами, <br /> оплачивает лицензию. Величина вознаграждения зависит от
          суммы платежей <br /> ваших клиентов за последние 12 месяцев.
        </p>

        <div className="clevel__container">
          <CLevel
            title="НОВИЧОК"
            description="Продажи менее 50 000 сум за предшествующие 12 месяцев"
            img={beginner}
            percent="25%"
            payment="на ваш счет от суммы платежа за Про версию"
          />

          <CLevel
            title="МАСТЕР"
            description="Продажи менее 50 000 сум за предшествующие 12 месяцев"
            img={master}
            percent="30%"
            payment="на ваш счет от суммы платежа за Про версию"
          />

          <CLevel
            title="ПРОФЕССИОНАЛ"
            description="Продажи менее 50 000 сум за предшествующие 12 месяцев"
            img={professional}
            percent="35%"
            payment="на ваш счет от суммы платежа за Про версию"
          />
        </div>
      </div>

      <div className="partner__support wrapper">
        <div className="partner__support-intro">
          <h2 className="partner__support-title">
            Ваш персональный <br /> менеджер
          </h2>
          <p className="partner__support-description">
            «Дорогие партнеры, я — Иванова Мария,буду рада помочь вам по всем
            вопросам,связанным с партнерской программой!»
          </p>
        </div>

        <div className="partner__support-details">
          <h2 className="partner__support-name">Мария Иванова</h2>
          <div className="partner__support-phone">
            <IoCallSharp className="partner__support-icon" />
            <a href="tel:+998 97 123 45 67">+998 97 123 45 67</a>
          </div>
          <div className="partner__support-email">
            <IoMdMail className="partner__support-icon" />
            <a href="mailto:abc@gmail.com">info@prochat.uz</a>
          </div>
        </div>
      </div>

      <div className="partner__features wrapper">
        <div className="partner__feature">
          <h2>Возможности</h2>
          <p>
            — Добавляйте клиентов вручную или автоматически, с помощью
            реферальной ссылки
          </p>
          <p>— Контроль платежей</p>
          <p>— Набор маркетинговых материалов</p>
          <p>— Поддержка 24/7</p>
          <p>
            — Партнеры ProChat могут делать скидку на оплату годового тарифа для
            пяти клиентов в месяц
          </p>
        </div>

        <div className="partner__feature">
          <h2>Условия</h2>
          <p>
            — Вознаграждения выплачиваются в сумах на расчетный счет по
            агентскому договору
          </p>
          <p>— С нерезидентов удерживается НДС 20%</p>
          <p>
            — Минимальная сумма вознаграждения длявывода - 100 000 сум. для
            резидентов, 300 000 руб. для нерезидентов
          </p>
          <p>
            — Для привлечения новых клиентов партнеры ProChat могут запрашивать
            промокоды на профессиональную лицензию ProChat на 30-дневный период
          </p>
        </div>
      </div>

      <div className="wrapper">
        <CApplication />
      </div>
    </>
  );
};

export default Partner;
