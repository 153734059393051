import React from "react";
import "./CButton.css";
import { CButtonProps } from "./CButton.types";

const CButton: React.FC<CButtonProps> = ({
  title,
  onClick,
  className = "",
  variant = "secondary",
  disabled,
  icon,
  style,
}) => {
  return (
    <button
      onClick={onClick}
      className={`cbtn cbtn__${variant} ${className}`}
      style={style}
      disabled={disabled}
    >
      {title} <span className="cbtn__icon">{icon}</span>
    </button>
  );
};

export default CButton;
