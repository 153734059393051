import React from "react";
import "./Sidebar.css";
import { SidebarProps } from "./Sidebar.types";
import CTitle from "../../components/CTitle";
import { IoExitOutline } from "react-icons/io5";
import { BsPeople } from "react-icons/bs";
import { IoChatbubbleEllipsesOutline, IoStatsChartOutline} from "react-icons/io5";
import { CiMail, CiFacebook, CiInstagram, CiWallet, CiSettings } from "react-icons/ci";
import { SlSocialVkontakte } from "react-icons/sl";
import { GoPlus } from "react-icons/go";
import { Link } from "react-router-dom";

const Sidebar: React.FC<SidebarProps> = () => {
  return (
    <div className="sidebar">
      <CTitle />

      <div className="sidebar__links">
        <h2>Диалоги</h2>

        <Link to={"/account/chat"} className="sidebar__link"> 
          <BsPeople />
          <p>Все <span className="sidebar__count">3</span></p>
        </Link>

        <Link to={"/account/chat"} className="sidebar__link">
          <IoChatbubbleEllipsesOutline />
          <p>Чат</p>
        </Link>

        <Link to={"/"} className="sidebar__link">
          <CiMail />
          <p>Почта <span className="sidebar__count">2</span></p>
        </Link>
      </div>

      <div className="sidebar__links">
        <h2>Подключить</h2>

        <Link to={"/"} className="sidebar__link">
          <IoChatbubbleEllipsesOutline />
          <p>Чат на сайте <GoPlus className="sidebar__plus" /></p>
        </Link>

        <Link to={"/"} className="sidebar__link">
          <CiMail />
          <p>Почта <GoPlus className="sidebar__plus" /></p>
        </Link>

        <Link to={"/"} className="sidebar__link">
          <CiFacebook />
          <p>Facebook <GoPlus className="sidebar__plus" /></p>
        </Link>

        <Link to={"/"} className="sidebar__link">
          <CiInstagram />
          <p>Instagram <GoPlus className="sidebar__plus" /></p>
        </Link>

        <Link to={"/"} className="sidebar__link">
          <SlSocialVkontakte />
          <p>Вконтакте <GoPlus className="sidebar__plus" /></p>
        </Link>
      </div>

      <div className="sidebar__links">
        <Link to={"/"} className="sidebar__link sidebar__link-item">
          <BsPeople />
          <p>Чат команды</p>
        </Link>

        <Link to={"/"} className="sidebar__link sidebar__link-item">
          <IoStatsChartOutline />
          <p>Отчеты</p>
        </Link>

        <Link to={"/"} className="sidebar__link sidebar__link-item">
          <CiWallet />
          <p>Тарифный план</p>
        </Link>

        <Link to={"/"} className="sidebar__link sidebar__link-item">
          <CiSettings />
          <p>Тарифный план</p>
        </Link>
      </div>

      <Link to={"/"} className="sidebar__link sidebar__link-exit">
        <IoExitOutline />
        <p>Настройки</p>
      </Link>
    </div>
  );
};

export default Sidebar;
