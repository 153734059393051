export const MOBILE_BREAKPOINT = 768;

export const BASE_API_URL = "https://supachat.1pay.uz";
// export const BASE_API_URL = "http://localhost:8080";

export const BASE_API = "https://prochat.uz";

export const RECAPTCHA_KEY = "6Lc-lAIqAAAAACkw-_n4YuCdULWsmhyWHdQL0XuL";

export const USER_TOKEN =
  localStorage.getItem("chatToken") ||
  "eyJhbGciOiJIUzUxMiIsInR5cCI6Imp3dCJ9.eyJVc2VySWQiOjI2NDMyLCJVc2VyTmFtZSI6Ijk5ODk5NDYwMTAyMyIsIkZpb05hbWUiOiJCYXh0bnVyIiwiUmVnRGF0ZSI6IjIwMjItMDctMTkiLCJVc2VyTGV2ZWwiOjIsIkNvdW50cnkiOiJcdTA0MGVcdTA0MTdcdTA0MTFcdTA0MTVcdTA0MWFcdTA0MThcdTA0MjFcdTA0MjJcdTA0MWVcdTA0MWQiLCJCaXJ0aERhdGUiOiIyMDAzLTEwLTIzIiwiQWNjZXNzTGV2ZWwiOjc1LCJpYXQiOjE3MTg3NzM3MDUsImV4cCI6MTcxOTAzMjkwNX0.6XFyxLNTx1gInPoaXpuHcgvFfiIokusC3bRpdBRzsd57kdlHDpcPG_yf_TYFiIm0wIMuFkCdF7eVHhkstwdsxg";
