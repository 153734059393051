import React, { useEffect, useState } from "react";
import "./Header.css";
import { IoIosSearch } from "react-icons/io";
import { IoCloseSharp, IoMenuSharp } from "react-icons/io5";
import { Link, useLocation } from "react-router-dom";

const Header: React.FC = () => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const location = useLocation();

  const toggleMenu = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  useEffect(() => {
    setIsOpenMenu(false);
  }, [location]);

  return (
    <header className="header wrapper">
      <Link to={"/"} className="header__title">
        Pro<span>Chat</span>
      </Link>

      <div className="header__links">
        <Link to={"about"} className="header__link">
          О нас
        </Link>

        <Link to={"tarif"} className="header__link">
          Тарифы
        </Link>

        <Link to={"partner"} className="header__link">
          Партнерам
        </Link>

        <a href="https://prochat.uz/blog/" className="header__link">
          Блог
        </a>
      </div>

      <div className="header__actions">
        <IoIosSearch className="header__search-icon" />
        <Link to={"/register"} className="header__register-btn">Зарегистрироваться</Link>
      </div>

      <IoMenuSharp onClick={toggleMenu} className="header__burger" />

      {isOpenMenu && (
        <div className="header__mobile-open">
          <IoCloseSharp onClick={toggleMenu} className="header__close-icon" />

          <Link to={"about"} className="header__link">
            О нас
          </Link>

          <Link to={"tarif"} className="header__link">
            Тарифы
          </Link>

          <Link to={"partner"} className="header__link">
            Партнерам
          </Link>

          <a href="https://prochat.uz/blog/" className="header__link">
            Блог
          </a>

          <Link to={"/register"} className="header__register-btn header__register-mobile-btn">
            Зарегистрироваться
          </Link>
        </div>
      )}
    </header>
  );
};

export default Header;
